.header {
  width: 90%;
  margin: 0 auto;
  padding: 15px;
  /* display: flex; */
  /* justify-content: space-between; */
}

.header__h1 {
  text-align: center;
}

.header__btn {
  border: none;
  font-size: 1.2em;
  color: #fff;
  background: #1f253d;
  display: block;
  margin: 5px auto;
}

.header__btn:focus,
.header__btn:hover {
  cursor: pointer;
}

@media only screen and (min-width: 500px) {
  .header__btn {
    float: right;
    position: relative;
    bottom: 40px;
  }
}
