* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #394264;
  min-width: 300px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (max-width: 768px) {
  body {
    background: #1f253d;
  }
}

.header {
  width: 90%;
  margin: 0 auto;
  padding: 15px;
  /* display: flex; */
  /* justify-content: space-between; */
}

.header__h1 {
  text-align: center;
}

.header__btn {
  border: none;
  font-size: 1.2em;
  color: #fff;
  background: #1f253d;
  display: block;
  margin: 5px auto;
}

.header__btn:focus,
.header__btn:hover {
  cursor: pointer;
}

@media only screen and (min-width: 500px) {
  .header__btn {
    float: right;
    position: relative;
    bottom: 40px;
  }
}

.search-form {
  background: #394264;
  width: 50%;
  min-width: 285px;
  height: 200px;
  margin: 0 auto;
  margin-top: 30px;
}

.search-form__top {
  background: #cc324b;
  text-align: center;
  height: 27%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.search-form__legend {
  color: #fff;
  font-size: 1.5em;
  font-weight: 700;
}

.flex-column {
  height: 70%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.search-form__input,
.search-form__btn {
  width: 55%;
  height: 35px;
  padding: 5px;
  font-size: 1em;
}

.search-form__btn {
  border: none;
  background-color: #11a8ab;
  color: #fff;
  font-weight: 700;
}

.search-form__btn:hover,
.search-form__btn:focus {
  cursor: pointer;
  background-color: #14bdc0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media only screen and (max-width: 568px) {
  .search-form__legend {
    font-size: 1em;
    padding: 5px;
  }
}

.results__container {
  width: 90%;
  margin: 20px auto;
}

.error {
  text-align: center;
}

.error > p {
  font-size: 1.5em;
}

.results__header {
  text-align: center;
  font-size: 1.75em;
}

.flex-container {
  width: 80%;
  margin: 10px auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.col {
  width: 30%;
}

.col__header {
  text-decoration: underline;
  text-transform: uppercase;
  color: #11a8ab;
}

ul {
  list-style: none;
  padding-top: 3px;
}

li {
  padding-top: 3px;
}

.bold {
  font-weight: 650;
}

@media only screen and (max-width: 568px) {
  .flex-container {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
  }

  .col {
    width: 80%;
    margin: 10px 0;
  }
}

.App {
  height: 100vh;
  width: 100vw;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.container {
  width: 100%;
  height: 100%;
  background: #1f253d;
  color: #fff;
}

@media only screen and (min-width: 768px) {
  .container {
    width: 95%;
    height: 92%;
    margin: 0 auto;
  }
}

