.App {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  width: 100%;
  height: 100%;
  background: #1f253d;
  color: #fff;
}

@media only screen and (min-width: 768px) {
  .container {
    width: 95%;
    height: 92%;
    margin: 0 auto;
  }
}
