.results__container {
  width: 90%;
  margin: 20px auto;
}

.error {
  text-align: center;
}

.error > p {
  font-size: 1.5em;
}

.results__header {
  text-align: center;
  font-size: 1.75em;
}

.flex-container {
  width: 80%;
  margin: 10px auto;
  display: flex;
  justify-content: space-between;
}

.col {
  width: 30%;
}

.col__header {
  text-decoration: underline;
  text-transform: uppercase;
  color: #11a8ab;
}

ul {
  list-style: none;
  padding-top: 3px;
}

li {
  padding-top: 3px;
}

.bold {
  font-weight: 650;
}

@media only screen and (max-width: 568px) {
  .flex-container {
    flex-direction: column;
    align-items: center;
  }

  .col {
    width: 80%;
    margin: 10px 0;
  }
}
