.search-form {
  background: #394264;
  width: 50%;
  min-width: 285px;
  height: 200px;
  margin: 0 auto;
  margin-top: 30px;
}

.search-form__top {
  background: #cc324b;
  text-align: center;
  height: 27%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-form__legend {
  color: #fff;
  font-size: 1.5em;
  font-weight: 700;
}

.flex-column {
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.search-form__input,
.search-form__btn {
  width: 55%;
  height: 35px;
  padding: 5px;
  font-size: 1em;
}

.search-form__btn {
  border: none;
  background-color: #11a8ab;
  color: #fff;
  font-weight: 700;
}

.search-form__btn:hover,
.search-form__btn:focus {
  cursor: pointer;
  background-color: #14bdc0;
  transition: 0.3s;
}

@media only screen and (max-width: 568px) {
  .search-form__legend {
    font-size: 1em;
    padding: 5px;
  }
}
